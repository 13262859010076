"use client";

import { FileQuestion, Home } from "lucide-react";
import Link from "next/link";
import { redirect } from "next/navigation";

import GoBackButton from "@/components/navigation/GoBackButton";

export default function NotFound() {
  redirect("/buy-used-cars-uae");

  return (
    <div className="inter flex min-h-[70vh] flex-col items-center justify-center px-4 py-16 text-center">
      <div className="mb-6 rounded-full bg-blue p-4">
        <FileQuestion className="h-10 w-10 text-primary" />
      </div>

      <h1 className="urbanist mb-3 text-3xl font-bold text-gray-900">Page Not Found</h1>

      <p className="mb-8 max-w-md text-secondary-foreground">
        We couldn&apos;t find the page you&apos;re looking for. It might have been moved, deleted, or never existed.
      </p>

      <div className="flex flex-col gap-4 sm:flex-row">
        <GoBackButton />
        <Link
          href="/"
          className="inter flex items-center justify-center gap-2 rounded-md bg-primary px-6 py-3 text-sm font-semibold text-white transition-all duration-300 hover:cursor-pointer hover:bg-primary-dark"
          aria-label="Go to homepage"
          tabIndex={0}
        >
          <Home className="h-4 w-4" />
          <span>Back to Home</span>
        </Link>
      </div>
    </div>
  );
}
