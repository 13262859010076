"use client";

import { ArrowLeft } from "lucide-react";
import { useRouter } from "next/navigation";

const GoBackButton: React.FC = () => {
  const router = useRouter();

  return (
    <button
      onClick={() => router.back()}
      className="inter flex items-center justify-center gap-2 rounded-lg bg-white px-6 py-3 text-sm font-semibold text-gray-700 transition-all duration-300 hover:cursor-pointer hover:bg-secondary-light"
      aria-label="Go back to previous page"
      tabIndex={0}
    >
      <ArrowLeft className="h-4 w-4" />
      <span>Go Back</span>
    </button>
  );
};

export default GoBackButton;
